import styled from "styled-components"
import { font } from "../../../atoms/typography"
import { color, buttonThemes } from "../../../atoms/colors"
import { Link } from "gatsby"

export const PriceCard = styled.div`
  background-color: ${color.common.white};
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 24px 24px 40px 24px;
  @media (max-width: 1010px) {
    padding: 24px 0px 0px 0px;
  }
`

export const PriceCardHeadingWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
`

export const PriceCardKicker = styled.p`
  ${font("kicker", "sm", "900")}
  color: ${color.common.workgridBlue};
  text-transform: uppercase;
  min-height: 14px;
`

export const PriceCardHeading = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 8px;
  align-self: stretch;
`

export const PriceCardPrice = styled.h3`
  ${font("display", "sm", "700")}
  color: ${color.common.black};
  text-align: center;
`

export const PriceCardPriceCicle = styled.h5`
  ${font("text", "reg", "400")}
  color: ${color.common.black};
`

export const PriceCardSubheading = styled.p`
  ${font("text", "reg", "400")}
  color: ${color.grey[600]};
  text-align: center;
  min-height: 48px;
`

export const PriceCardBodyCopyWrap = styled.div`
  display: flex;
  flex-direction: column;
`

export const PriceCardCopy = styled.div`
  * {
    ${font("text", "reg")}
    color: ${color.grey[600]};
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`


export const PriceCardCTA = styled(Link)`
  ${buttonThemes.primary}
  ${font("text", "sm", "700")}
  cursor: pointer;
  border: none;
`
