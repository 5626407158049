import React, { useState, useEffect } from "react"
import {
  TableWrapper,
  HeaderRow,
  Button,
  Dropdown,
  Row,
} from "../styles/Table.styled"
import TableItem from "./TableItem"
import DownArrow from "../assets/down-arrow.svg"

const Table = ({ component, selectedOption, isMobile, index }) => {
  const [tableOpen, setTableOpen] = useState(index === 0)
  const [tableData, setTableData] = useState([])

  useEffect(() => {
    setTableData(transposeData(component.tableColumns))
  }, [])

  const transposeData = data => {
    if (!data || data.length === 0) return []

    const rowCount = data[0]?.tableItems?.length || 0
    const colCount = data.length

    const transposedData = Array.from({ length: rowCount }, () => [])

    data.forEach(col => {
      col.tableItems.forEach((item, rowIndex) => {
        if (!transposedData[rowIndex]) {
          transposedData[rowIndex] = []
        }
        transposedData[rowIndex].push(item)
      })
    })

    return transposedData
  }

  return (
    <TableWrapper>
      <HeaderRow>
        <div>{component?.tableTitle}</div>
        <Button onClick={() => setTableOpen(!tableOpen)} tableOpen={tableOpen}>
          <img src={DownArrow} alt="icon" />
        </Button>
      </HeaderRow>

      <Dropdown tableOpen={tableOpen}>
        {tableData.map((row, idx) => (
          <Row key={idx}>
            {row.map((item, index) =>
              isMobile ? (
                (index === 0 || index === selectedOption.value) && (
                  <TableItem key={index} idx={index} item={item} />
                )
              ) : (
                <TableItem key={index} idx={index} item={item} />
              )
            )}
          </Row>
        ))}
      </Dropdown>
    </TableWrapper>
  )
}

export default Table
