import styled from "styled-components"

export const Item = styled.div`
  flex: 1;
  padding: 16px 24px;
  font-family: "Roboto";
  font-style: normal;
  font-size: 22px;
  font-weight: ${props => (props.idx > 0 ? 400 : 700)};
  color: ${props => (props.idx > 0 ? "#5F5867" : "#0E0416")};
  text-align: ${props => (props.idx > 0 ? "center" : "left")};
  background: #ffffff;
  @media (max-width: 990px) {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #d0d5dd;
    padding: 16px 5px;
    font-size: 16px;
  }
`

export const Htmldiv = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  margin-top: 12px;
  color: ${props => (props.idx > 0 ? "#5F5867" : "#0E0416")};
  @media (max-width: 990px) {
    width: 100%;
    text-align: center;
    padding: 16px 5px;
    font-size: 14px;
  }
`
