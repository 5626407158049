import React, { useState, useEffect } from "react"
import {
  Section,
  Wrapper,
  Header,
  Kicker,
  Heading,
  Subheading,
  CardItemsWrapper,
} from "./styles/PricingTable.styled"
import Table from "./components/Table"
import { CardItems } from "./components/CardItems"
import CustomSelect from "../Select"
import { defaultResolveRichText } from "../../utils/resolve-rich-text/defaultResolveRichText"
import useScreenResponsive from "../../utils/screenResponsive"

const PricingTable = ({ component }) => {
  const isMobile = useScreenResponsive(990)
  const [priceItems, setPriceItems] = useState(component?.priceItems)
  const selectCompOptions = component?.priceItems?.map((item, index) => ({
    value: index,
    label: item.heading,
  }))

  const [selectedOption, setSelectedOption] = useState(selectCompOptions[1])

  useEffect(() => {
    if (selectedOption && isMobile) {
      setPriceItems(
        component?.priceItems.filter(
          item => item.heading === selectedOption.label
        )
      )
    } else {
      setPriceItems(component?.priceItems)
    }
  }, [selectedOption, component, isMobile])

  const handleItemSelect = option => {
    setSelectedOption(option)
  }

  return (
    <Section>
      <Wrapper>
        <Header>
          <Kicker>{component?.kicker}</Kicker>
          <Heading>{component?.heading}</Heading>
          {component.subheading && (
            <Subheading>
              {defaultResolveRichText(component?.subheading)}
            </Subheading>
          )}
        </Header>
        <CustomSelect
          options={selectCompOptions}
          value={selectedOption}
          onChange={handleItemSelect}
          placeholder="Select..."
          className="itemSelect"
        />

        <CardItemsWrapper length={priceItems?.length}>
          {priceItems &&
            priceItems?.map(card => <CardItems card={card} key={card?.id} />)}
        </CardItemsWrapper>
        {component?.itemTables.map((table, index) => (
          <Table
            key={index}
            component={table}
            selectedOption={selectedOption}
            isMobile={isMobile}
            index={index}
          />
        ))}
      </Wrapper>
    </Section>
  )
}

export default PricingTable
