import React from "react"
import { switchbackResolveRichText } from "../../../utils/resolve-rich-text/switchbackResolveRichText"
import {
  PriceCard,
  PriceCardHeadingWrap,
  PriceCardKicker,
  PriceCardHeading,
  PriceCardPrice,
  PriceCardPriceCicle,
  PriceCardSubheading,
  PriceCardCopy,
  PriceCardCTA,
  PriceCardBodyCopyWrap,
} from "../styles/CardItems.styled"

export const CardItems = ({ card }) => {
  const {
    heading,
    subheading,
    bodyCopy,
    bodyCopy2,
    callsToAction,
    price,
    priceCicle,
  } = card

  return (
    <PriceCard>
      <PriceCardHeadingWrap>
        {<PriceCardKicker>{heading}</PriceCardKicker>}
        {price && (
          <PriceCardHeading>
            <PriceCardPrice>{price}</PriceCardPrice>
            <PriceCardPriceCicle>{priceCicle}</PriceCardPriceCicle>
          </PriceCardHeading>
        )}
        {subheading && <PriceCardSubheading>{subheading}</PriceCardSubheading>}
      </PriceCardHeadingWrap>

      {callsToAction &&
        callsToAction.map(cta => (
          <PriceCardCTA key={cta?.id} to={cta?.link}>
            {cta?.label}
          </PriceCardCTA>
        ))}
      <PriceCardBodyCopyWrap>
        {bodyCopy && (
          <PriceCardCopy>
            {switchbackResolveRichText(bodyCopy, "persona-check")}
          </PriceCardCopy>
        )}
        {bodyCopy2 && (
          <PriceCardCopy>
            {switchbackResolveRichText(bodyCopy2, "cross-mark")}
          </PriceCardCopy>
        )}
      </PriceCardBodyCopyWrap>
    </PriceCard>
  )
}
