import React from "react"
import { Item, Htmldiv } from "../styles/TableItem.styled"
import Check from "../assets/check.svg"
import X from "../assets/x.svg"
import { defaultResolveRichText } from "../../../utils/resolve-rich-text/defaultResolveRichText"

const TableItem = ({ item, idx }) => (
  <Item idx={idx}>
    {item?.showCheck && <img src={Check} alt="check" />}
    {item?.content && item?.content !== "" && <div>{item?.content}</div>}
    {item?.htmlContent && (
      <Htmldiv>{defaultResolveRichText(item.htmlContent)}</Htmldiv>
    )}
    {item?.showEmpty && <img src={X} alt="empty" />}
  </Item>
)

export default TableItem
